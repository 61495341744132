import Box from "@mui/material/Box"
import { useSelector } from "react-redux"
import { isDefined } from "../../config/helpers";
import useWindowSize from "../../_hooks/useWindowSize";

/** SSR component which will change width of the page depending on: 
 * 1 - template page settings 
 * 2 - is mobile
 * @returns component with children either boxed or full width depending on props
 */


const BoxContainer = (props) => {
    const {isMobile} = useWindowSize();
    
    const {
        children,  
        className = "", 
        mobileClasses, 
        template_page_settings,
        ...other
    } = props;

    const { template_settings } = useSelector((state) => state.globalReducer);

    const pageLayout = isDefined(template_page_settings) && isDefined(template_page_settings.layout) ? template_page_settings.layout : null
    const boxPadding = (pageLayout == 'box' || template_settings.layout == 'box') ? 'boxed-padding' : "";
    const includeMobileClasses = isMobile ? mobileClasses : '';

    return ( 
        <Box
            {...other}
            className={`${className} ${boxPadding} ${includeMobileClasses}`}
        > 
            {children}
        </Box> 
    )
}

export default BoxContainer